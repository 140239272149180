export default [
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/templates/clients/Clients.vue'),
    // component: () => import('@/views/templates/ComingSoon.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  {
    path: '/clients/profile/:action',
    name: 'client-profile',
    component: () => import('@/views/templates/clients/Profile.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  {
    path: '/clients/profile/edit/form',
    name: 'client-profile-edit',
    component: () => import('@/views/templates/clientdashboard/ProfileEdit.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  {
    path: '/clients/client-assessment-care/:tabIndex/:action',
    name: 'admin-client-assessmentcare',
    component: () => import('@/views/templates/client-assessmentcare/Assessmentcare.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  {
    path: '/clients/preferences/:action',
    name: 'client-preferences',
    component: () => import('@/views/templates/clients/Preferences.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  {
    path: '/clients/family/:action',
    name: 'client-family',
    component: () => import('@/views/templates/clients/Family.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  {
    path: '/clients/checklist/:action',
    name: 'client-checklist',
    component: () => import('@/views/templates/clients/Checklist.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  {
    path: '/clients/careplan/:action',
    name: 'client-careplan',
    component: () => import('@/views/templates/careplan/CarePlan.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  {
    path: '/family',
    name: 'client-client-family',
    component: () => import('@/views/templates/clients/Family.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
  {
    path: '/checklist',
    name: 'client-client-checklist',
    component: () => import('@/views/templates/clients/Checklist.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
  {
    path: '/preferences',
    name: 'client-client-preferences',
    component: () => import('@/views/templates/clients/Preferences.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
  {
    path: '/carelogs',
    name: 'client-client-carelogs',
    component: () => import('@/views/templates/clients/Profile.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
  {
    path: '/client-assessment&care/:tabIndex',
    name: 'client-assessmentcare',
    component: () => import('@/views/templates/client-assessmentcare/Assessmentcare.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
  {
    path: '/my-clients/profile/',
    name: 'caregiver-client-profile',
    component: () => import('@/views/templates/clients/Profile.vue'),
    meta: {
      resource: 'AgentAccess',
    },
  },
  {
    path: '/my-clients/assessment-care/:tabIndex',
    name: 'caregiver-client-assessmentcare',
    component: () => import('@/views/templates/client-assessmentcare/Assessmentcare.vue'),
    meta: {
      resource: 'AgentAccess',
    },
  },
]
