export default [
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/templates/history/History.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
]
