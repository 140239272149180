import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-plugin-persistedstate'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import acctSettingModule from '@/views/pages/account-setting/AccountSettingStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

const dataState = createPersistedState({
  key: 'perstate',
  paths: ['account_setting'],
  // storage: sessionStorage,
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    account_setting: acctSettingModule,
  },
  plugins: [dataState],
  strict: process.env.DEV,
})
