// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'

const userData = getUserData()

export default [
  {
    path: '/hr',
    name: 'hr',
    component: () => import('@/views/templates/hr/Hr.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form19/:action',
    name: 'apps-hr-form19',
    component: () => import('@/views/apps/hr/hr-form/HrForm19.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form18/:action',
    name: 'apps-hr-form18',
    component: () => import('@/views/apps/hr/hr-form/HrForm18.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form17a/:action',
    name: 'apps-hr-form17a',
    component: () => import('@/views/apps/hr/hr-form/HrForm17a.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form17b/:action',
    name: 'apps-hr-form17b',
    component: () => import('@/views/apps/hr/hr-form/HrForm17b.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form/:action',
    name: 'apps-hr-form',
    component: () => import('@/views/apps/hr/hr-form/HrForm.vue'),
    /* beforeEnter: (to, from, next) => {
      // if (userData !== null) {
      if ((to.query.id === undefined || to.query.id === '' || to.query.id === null)) {
      // if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
        return next({ name: 'misc-not-authorized' })
      }
      // }

      return next()
    }, */
    /* eslint-disable */
    /* beforeEnter: async (to, from, next) => { 
      let udata = getUserData()
      if (udata.role === 'agent' && typeof to.query.id === 'undefined' ) {
        next({name: to.name, params : to.params, query : { id : udata.cbiId }})
      } else if ( udata.role === 'agent' && to.query.id !== udata.cbiId && typeof to.query.id !== 'undefined' ){
        next({ name: 'misc-not-authorized' })
      } else {
        next()
      }
    }, */
    /* eslint-enable */
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form1/:action',
    name: 'apps-hr-form1',
    component: () => import('@/views/apps/hr/hr-form/HrForm1.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form2/:action',
    name: 'apps-hr-form2',
    component: () => import('@/views/apps/hr/hr-form/HrForm2.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form3/:action',
    name: 'apps-hr-form3',
    component: () => import('@/views/apps/hr/hr-form/HrForm3.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form4/:action',
    name: 'apps-hr-form4',
    component: () => import('@/views/apps/hr/hr-form/HrForm4.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form5/:action',
    name: 'apps-hr-form5',
    component: () => import('@/views/apps/hr/hr-form/HrForm5.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form6/:action',
    name: 'apps-hr-form6',
    component: () => import('@/views/apps/hr/hr-form/HrForm6.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form7/:action',
    name: 'apps-hr-form7',
    component: () => import('@/views/apps/hr/hr-form/HrForm7.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form8/:action',
    name: 'apps-hr-form8',
    component: () => import('@/views/apps/hr/hr-form/HrForm8.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form9/:action',
    name: 'apps-hr-form9',
    component: () => import('@/views/apps/hr/hr-form/HrForm9.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form10/:action',
    name: 'apps-hr-form10',
    component: () => import('@/views/apps/hr/hr-form/HrForm10.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form11/:action',
    name: 'apps-hr-form11',
    component: () => import('@/views/apps/hr/hr-form/HrForm11.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form12/:action',
    name: 'apps-hr-form12',
    component: () => import('@/views/apps/hr/hr-form/HrForm12.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form13/:action',
    name: 'apps-hr-form13',
    component: () => import('@/views/apps/hr/hr-form/HrForm13.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form14a/:action',
    name: 'apps-hr-form14a',
    component: () => import('@/views/apps/hr/hr-form/HrForm14a.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form14b/:action',
    name: 'apps-hr-form14b',
    component: () => import('@/views/apps/hr/hr-form/HrForm14b.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form14c/:action',
    name: 'apps-hr-form14c',
    component: () => import('@/views/apps/hr/hr-form/HrForm14c.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form15/:action',
    name: 'apps-hr-form15',
    component: () => import('@/views/apps/hr/hr-form/HrForm15.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form16/:action',
    name: 'apps-hr-form16',
    component: () => import('@/views/apps/hr/hr-form/HrForm16.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form20/:action',
    name: 'apps-hr-form20',
    component: () => import('@/views/apps/hr/hr-form/HrForm20.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form21/:action',
    name: 'apps-hr-form21',
    component: () => import('@/views/apps/hr/hr-form/HrForm21.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
  {
    path: '/apps/hr/form22/:action',
    name: 'apps-hr-form22',
    component: () => import('@/views/apps/hr/hr-form/HrForm22.vue'),
    beforeEnter: (to, from, next) => {
      if (userData !== null) {
        if (userData.role === 'agent' && to.query.id !== userData.cbiId) {
          return next({ name: 'misc-not-authorized' })
        }
      }

      return next()
    },
    meta: {
      resource: (() => {
        if (userData !== null) {
          return userData.role === 'admin' ? 'AdminAccess' : 'AgentAccess'
        }

        return 'AdminAccess'
      })(),
    },
  },
]
