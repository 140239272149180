import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  invoices: [
    {
      id: 4987,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Jordan Stevenson',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: '',
    },
    {
      id: 4988,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Stephanie Burns',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/10-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4989,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Tony Herrera',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/1-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4990,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Kevin Patton',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/9-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4991,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Mrs. Julie Donovan MD',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/10-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4992,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Amanda Phillips',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: '',
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4993,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Christina Collier',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: '',
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4994,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'David Flores',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/9-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4995,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Valerie Perez',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/2-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4996,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Susan Dickerson',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/9-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4997,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Kelly Smith',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/4-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4998,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Jamie Jones',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/5-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 4999,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Ruben Garcia',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/7-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5000,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Ryan Meyer',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/9-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5001,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Valerie Valdez',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/2-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5002,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Melissa Wheeler',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/6-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5003,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Alan Jimenez',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: '',
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5004,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Jennifer Morris',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/7-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5005,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Timothy Stevenson',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: '',
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5006,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Erik Hayden',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/6-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5007,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Katherine Kennedy',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/1-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5008,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Monica Fuller',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/8-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5009,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Stacey Carter',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/3-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5010,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Chad Davis',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/2-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5011,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Chris Reyes',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: '',
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5012,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Laurie Summers',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/4-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5013,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Lindsay Wilson',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/10-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5014,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Jenna Castro',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: '',
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5015,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Wendy Weber',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/7-small.png'),
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5016,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'April Yates',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: '',
      caregiver: 'Howell, Cynthia',
    },
    {
      id: 5017,
      location: 'Terraces at San Joaquin Gardens - Fresno',
      client: {
        name: 'Daniel Marshall PhD',
      },
      last_visited: '06/12/2021',
      family_login: 'Invite | Login',
      contact: 'H: (425) 123-3456 M: (425) 123-6543',
      avatar: require('@/assets/images/avatars/1-small.png'),
      caregiver: 'Howell, Cynthia',
    },
  ],
}

/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/invoice/invoices').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null, clientData = null } = config.params
  /* eslint-enable */
  console.log('mockdb')
  console.log(clientData)
  console.log(config.params)
  const queryLowered = q.toLowerCase()
  const filteredData = data.invoices.filter(
    invoice =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (
        invoice.client.name.toLowerCase().includes(queryLowered)) &&
      invoice.invoiceStatus === (status || invoice.invoiceStatus),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      invoices: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})
