export default [
  {
    path: '/agenttasks/:id',
    name: 'agenttasks-tasks',
    component: () => import('@/views/templates/agenttasks/Agenttasks.vue'),
    meta: {
      resource: 'AgentAccess',
    },
  },
  // {
  //   path: '/agenttasks/my-tasks/:id',
  //   name: 'agenttasks-tasks',
  //   component: () => import('@/views/templates/agenttasks/agent-task-list/AgentTasksList.vue'),
  //   meta: {
  //     resource: 'AgentAccess',
  //   },
  // },
]
