export default [
  {
    path: '/people',
    name: 'people',
    component: () => import('@/views/templates/people/People.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
]
