import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'
import landing from './routes/landing'
import clients from './routes/clients'
import caregiver from './routes/caregiver'
// import carefinder from './routes/carefinder'
// eslint-disable-next-line import/no-cycle
// import accounting from './routes/accounting'
// eslint-disable-next-line import/no-cycle
import hr from './routes/hr'
// import broadcast from './routes/broadcast'
/* import reports from './routes/reports' */
import history from './routes/history'
import attendance from './routes/attendance'
import schedule from './routes/schedule'
import agenttasks from './routes/agent-tasks'
import agentnotes from './routes/agent-notes'
import clientdashboard from './routes/clientdashboard'
import carelogs from './routes/care-logs'
import clientcalendar from './routes/clientcalendar'
import medications from './routes/medications'
import clienttodo from './routes/clienttodo'
import people from './routes/people'
import careplan from './routes/care-plan.js'
import notifications from './routes/notifications'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'landing' } },
    ...apps,
    // ...dashboard,
    ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    ...landing,
    ...clients,
    ...caregiver,
    // ...carefinder,
    // ...accounting,
    ...hr,
    // eslint-disable-next-line
    // ...broadcast,
    // ...reports,
    ...history,
    ...attendance,
    ...schedule,
    ...agenttasks,
    ...agentnotes,
    ...clientdashboard,
    ...carelogs,
    ...clientcalendar,
    ...medications,
    ...clienttodo,
    ...people,
    ...careplan,
    ...notifications,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    if (to.name === 'landing') {
      const userData = getUserData()
      return next(getHomeRouteForLoggedInUser(userData.role))
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// set last route full path
router.afterEach(to => {
  if (['auth-login', 'auth-register', 'auth-forgot-password', 'auth-reset-password', 'misc-coming-soon', 'misc-not-authorized', 'misc-under-maintenance', 'misc-error', 'error-404'].indexOf(to.name) === -1) {
    localStorage.setItem('lastKnownRoute', JSON.stringify(to))
  }
})

export default router
