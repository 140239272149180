export default [
  {
    path: '/care-plan',
    name: 'care-plan',
    component: () => import('@/views/templates/careplan/CarePlan.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
]
