export default [
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import('@/views/templates/schedule/Schedule.vue'),
    meta: {
      resource: 'AgentAccess',
    },
  },
]
