/* eslint-disable */
import axios from '@axios'
import Vue from 'vue'
// import { reactive } from '@vue/composition-api'

export default {
  namespaced: true,
  state: {
    general: {
      first_name: '',
      last_name: '',
      email_address: '',
      profile_picture: '',
    },
    fam_member_information: {
      middle_name: '',
      home_phone: '',
      mobile_phone: '',
      work_phone: '',
      primarycontactnumber: '',
    },
  },
  mutations: {
    SET_ACCOUNT_SETTING_GENERAL: (state, payload) => (state.general = payload),
    SET_ACCOUNT_SETTING_FM_INFORMATION: (state, payload) => (state.fam_member_information = payload),
    SET_PROF_PIC: (state, payload) => {
      state.general.profile_picture = payload
    },
  },
  getters: {
    
  },
  actions: {
    getAccountSettingsData(ctx, clientId) {
      const uData = JSON.parse(localStorage.getItem('userData'))
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line prefer-template
          .post(Vue.prototype.$apiUrl2 + 'AccountSetting/getAccountSettingsData', {
              id: uData.id,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'application/json',
              },
            }
          )
          .then(response => {
            let data = response.data.response.data
            if (response.data.response.data.length !== 0) {
              ctx.commit('SET_ACCOUNT_SETTING_GENERAL', {
                first_name: data.first_name,
                last_name: data.last_name,
                email_address: data.email,
                profile_picture: data.profile_picture,
              })
              
              if(data.family_id){
                ctx.commit('SET_ACCOUNT_SETTING_FM_INFORMATION', {
                  middle_name: data.middlename,
                  home_phone: data.homephone,
                  mobile_phone: data.cellphone,
                  work_phone: data.workphone,
                  primarycontactnumber: data.primarycontactnumber,
                })
              }
            }  
          })
          .catch(error => { reject(error) })
      })
    },
    /* getFamMemberData(ctx, id) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line prefer-template
          .post(Vue.prototype.$apiUrl2 + 'AccountSetting/getFamMemberData', {
              id: id,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'application/json',
              },
            }
          )
          .then(response => {
            let data = response.data.response.data
            if (response.data.response.data.length !== 0) {
              const tempData = []
                tempData.push({
                  middle_name: data.middle_name,
                  home_phone: data.home_phone,
                  mobile_phone: data.mobile_phone,
                  work_phone: data.work_phone,
                })
              ctx.commit('SET_ACCOUNT_SETTING_FM_INFORMATION', tempData[0])
            }  
          })
          .catch(error => { reject(error) })
      })
    }, */
    ChangeProfilePicture(ctx, formData) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}AccountSetting/changeProfilePicture`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    removeProfPic(ctx, formData) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}AccountSetting/removeProfPic`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    saveGeneralData(ctx, formData) {
      /* for (const [key, value] of formData.entries()) {
        console.log(key, value);
      } */

      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}AccountSetting/saveGeneralData`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    saveInformationData(ctx, formData) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}AccountSetting/saveInformationData`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
  }
}