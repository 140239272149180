// eslint-disable-next-line import/no-cycle
import router from '@/router'

// eslint-disable-next-line no-unused-vars
import ability from '@/libs/acl/ability'
// eslint-disable-next-line no-unused-vars
import { initialAbility } from '@/libs/acl/config'

import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  isAlert = true

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        if (response.data.accessToken) {
          // router.go()
        }
        // console.log('hello')
        return response
      },
      error => {
        // console.log('hello2')
        // const { config, response: { status } } = error
        const { config, response } = error
        // eslint-disable-next-line no-unused-vars
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          // Check if refresh token is expired
          // console.log('refresh  token', this.getRefreshToken())
          const refreshToken = this.getRefreshToken()
          if (refreshToken) {
            const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]))
            const now = Math.ceil(Date.now() / 1000)
            // console.log('is token valid', tokenParts.exp > now)

            if (tokenParts.exp > now) {
              if (!this.isAlreadyFetchingAccessToken) {
                this.isAlreadyFetchingAccessToken = true
                this.refreshToken().then(r => {
                  // console.log('new token', r.data.refreshToken)
                  this.isAlreadyFetchingAccessToken = false
                  // Update accessToken in localStorage
                  this.setToken(r.data.accessToken)
                  this.setRefreshToken(r.data.refreshToken)

                  this.onAccessTokenFetched(r.data.accessToken)
                }).catch(e => {
                  if (e.response.statusText === 'Invalid Token') {
                    this.removeToken()
                    this.removeRefreshToken()

                    localStorage.removeItem('userData')

                    ability.update(initialAbility)

                    router.push({ name: 'auth-login' })
                  }
                })
              }
              const retryOriginalRequest = new Promise(resolve => {
                this.addSubscriber(accessToken => {
                  // Make sure to assign accessToken according to your response.
                  // Check: https://pixinvent.ticksy.com/ticket/2413870
                  // Change Authorization header
                  originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                  this.axiosIns.defaults.headers.Authorization = `Bearer ${accessToken}`
                  return resolve(this.axiosIns(originalRequest))
                })
              })
              return retryOriginalRequest
            }
            if (!(tokenParts.exp > now)) {
              console.log('expire refresh token', this.isAlert)

              if (this.isAlert) {
                this.isAlert = false
                // eslint-disable-next-line no-alert
                alert('Sorry your token session expired. Please re-login your account')
              }

              this.removeToken()
              this.removeRefreshToken()

              localStorage.removeItem('userData')

              ability.update(initialAbility)

              router.push({ name: 'auth-login' })
              return Promise.reject(error)
            }
          } else {
            console.log('no refresh token', this.isAlert)
            // eslint-disable-next-line no-alert
            if (this.isAlert) {
              this.isAlert = false
              // eslint-disable-next-line no-alert
              alert('No refresh token')
            }
            this.removeToken()
            this.removeRefreshToken()

            localStorage.removeItem('userData')

            ability.update(initialAbility)

            router.push({ name: 'auth-login' })
            return Promise.reject(error)
          }
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
  }

  removeRefreshToken() {
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    },
    {
      headers: {
        'Content-type': 'application/json',
      },
    })
  }
}
