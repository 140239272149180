export default [
  {
    path: '/caregiver',
    name: 'caregiver',
    component: () => import('@/views/templates/caregiver/Caregiver.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  {
    path: '/my-clients',
    name: 'my-clients',
    component: () => import('@/views/templates/clients/Clients.vue'),
    meta: {
      resource: 'AgentAccess',
    },
  },
  {
    path: '/caregiver/view/:caregiverId',
    name: 'caregiver-view',
    component: () => import('@/views/templates/caregiver/view/CaregiverView.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
]
