export default [
  {
    path: '/',
    name: 'landing',
    component: () => import('@/views/templates/landing/Landing.vue'),
    // component: () => import('@/views/templates/ComingSoon.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  /* {
    path: '/clients/profile/:action',
    name: 'client-profile',
    component: () => import('@/views/templates/clients/Profile.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  },
  {
    path: '/caregiver/view/:caregiverId',
    name: 'caregiver-view',
    component: () => import('@/views/templates/caregiver/view/CaregiverView.vue'),
    meta: {
      resource: 'AdminAccess',
    },
  }, */
  {
    path: '/caregiverDashboard',
    name: 'caregiverLanding',
    component: () => import('@/views/templates/caregiverdashboard/CaregiverDashboard.vue'),
    meta: {
      resource: 'AgentAccess',
    },
  },
]
