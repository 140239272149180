export default [
  {
    path: '/attendance',
    name: 'attendance',
    component: () => import('@/views/templates/attendance/Attendance.vue'),
    meta: {
      resource: 'AgentAccess',
    },
  },
  {
    path: '/caregiver-shift-calendar',
    name: 'shift-calendar',
    component: () => import('@/views/templates/caregivercalendar/CaregiverCalendar.vue'),
    meta: {
      resource: 'AgentAccess',
    },
  },
]
