export default [
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/templates/notifications/Notifications.vue'),
    meta: {
      resource: 'Auth',
    },
  },
]
