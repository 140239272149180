export default {
  // Endpoints
  loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  // eslint-disable-next-line
  // refreshEndpoint: ( process.env.NODE_ENV === 'development' ? 'https://coc-dev.motivit.com' : 'https://coc.motivit.com' ) + '/api/auth/refreshToken', // '/auth/refreshToken',
  refreshEndpoint: `${process.env.VUE_APP_API_BASE_URL2}auth/refreshToken`,
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
