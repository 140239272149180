export default [
  {
    path: '/clientdashboard',
    name: 'clientdashboard',
    component: () => import('@/views/templates/clientdashboard/Clientdashboard.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
  {
    path: '/clientdashboard/profile-edit',
    name: 'profile-edit',
    component: () => import('@/views/templates/clientdashboard/ProfileEdit.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
]
