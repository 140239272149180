export default [
  {
    path: '/clienttodo',
    name: 'clienttodo',
    component: () => import('@/views/templates/clienttodo/Clienttodo.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
]
