export default [
  {
    path: '/client-shift-calendar',
    name: 'clientcalendar',
    component: () => import('@/views/templates/clientcalendar/Clientcalendar.vue'),
    meta: {
      resource: 'ClientAccess',
    },
  },
]
